@import 'config';
@import 'util';

body {
	font-family     : "Lucida Grande", Lucida, Verdana, sans-serif;
	padding         : 0;
	margin          : 0;
	overflow        : hidden;
	background-color: #000;
}

.note {
	padding: 20px;
	margin : 20px;
	border : 5px dashed #ddd;

	&.alarm {
		border-color: red;
	}
}

#MAIN {}

.led_value {
	border          : 2px outset white;
	width           : 8px;
	height          : 8px;
	background-color: #000;
	border-radius   : 7px;
	box-shadow      : 0 2px 2px #555;
	display         : inline-block;

	&.WARNING {
		background-color: #ff3;
		//box-shadow: 0 0 9px 6px rgba(255,255,0,0.3);
	}

	&.OFF {
		background-color: #000;
	}

	&.NORMAL,
	&.ON {
		background-color: #3f3;
	}

	&.ALARM,
	&.OFF {
		background-color                 : #f55;
		-webkit-animation                : redblink 1s;
		-webkit-animation-iteration-count: infinite;
	}
}
@import "../rt/rt";
@import "../dt/dt";
@import "../models/database/database.scss";
@import "../models/device/device.scss";
@import "../models/device_library/device_library.scss";
@import "../models/files/files.scss";
@import "../models/network/network.scss";
@import "../models/poller/poller.scss";
@import "../models/screen/screen.scss";
@import "../models/settings/settings.scss";
@import "../models/tag/tag.scss";
@import "../models/report/report.scss";
@import "../models/user/user.scss";
@import "../models/events/events.scss";
@import "link.scss";
@import 'widget';
